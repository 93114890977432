









































import breakpoints from "library/assets/vars/breakpoints.json"

export default

	props: block: Object

	computed:
		baseCopy: -> @block?.baseCopy

		scrollCopy: -> @block?.scrollCopy

		textColor: -> @block?.textColor
		dimBackground: ->@block?.dimBackground

		buttons: -> @block?.buttons

		image: -> @block?.image
		video: -> @block?.video

		bodyOffset: -> @$store.state.layout.bodyOffset + @$store.state['notification-bar'].height

	mounted: ->
		baseHeight = @$refs.baseCopy?.offsetHeight || 0

		@$refs.copy.style.top = "calc(100% - #{baseHeight + 2}px)"

		if !@scrollCopy?.length then return

		@cleanupDesktopResponsiveMotion = @$setupResponsiveMotion @desktopMotionSetup,
		@desktopMotionCleanup, "(min-width: #{parseInt(breakpoints.tablet) + 1}px) and (min-height: 700px)"

		@cleanupMobileResponsiveMotion = @$setupResponsiveMotion @mobileMotionSetup,
		@mobileMotionCleanup, "(max-width: #{breakpoints.tablet}) and (min-height: 400px)"

	beforeUnmount: ->
		@cleanupDesktopResponsiveMotion() if @cleanupDesktopResponsiveMotion
		@cleanupMobileResponsiveMotion() if @cleanupMobileResponsiveMotion

	methods:
		desktopMotionSetup: ->
			@desktopTimeline = @$gsap.timeline()
				.fromTo @$refs.desktopBackground,
					{
						maxWidth: 1190
					},
					{
						maxWidth: 2560
						duration: 5
					}, 0.25
				.fromTo @$refs.desktopBackground,
					{ borderRadius: 30, 'filter': 'brightness(100%)' },
					{ borderRadius: 0, 'filter': 'brightness(60%)', duration: 1 }, 0
				.fromTo @$refs.copy, {yPercent: 0}, {yPercent: -100, duration: 5}, 1
				.fromTo @$refs.scrollCopy, {opacity: 0}, {opacity: 1, duration: 1}, 1

			@desktopTrigger = @$ScrollTrigger.create(
				trigger: @$el
				start: "top-=#{@bodyOffset+80}px top"
				end: "center top"
				scrub: true
				pin: @$refs.pin
				anticipatePin: 1
				animation: @desktopTimeline
			)
			@$ScrollTrigger.refresh()

		desktopMotionCleanup: ->
			@desktopTrigger?.kill?()
			@$refs.copy?.style?.transform = "none"

		mobileMotionSetup: ->
			@mobileTimeline = @$gsap.timeline()
				.fromTo @$refs.mobileBackground,
					{
						maxWidth: "87.18vw"
					},
					{
						maxWidth: "100vw"
						duration: 3
					}, 0.25
				.fromTo @$refs.background,
					{ borderRadius: 30, 'filter': 'brightness(100%)' },
					{ borderRadius: 0, 'filter': 'brightness(60%)', duration: 1 }, 0
				.fromTo @$refs.copy, {yPercent: 0}, {yPercent: -100, duration: 5}, 1
				.fromTo @$refs.scrollCopy, {opacity: 0}, {opacity: 1, duration: 1}, 1

			@mobileTrigger = @$ScrollTrigger.create(
				trigger: @$el
				start: "top-=#{@bodyOffset + 50}px top"
				end: "center top"
				scrub: true
				pin: @$refs.pin
				anticipatePin: 1
				animation: @mobileTimeline
			)
			@$ScrollTrigger.refresh()

		mobileMotionCleanup: ->
			@mobileTrigger?.kill?()
			@$refs.copy?.style?.transform = "none"

