









export default

	props:
		buttons: Array
		size:
			type: String
			default: 'small'

